import Dom from "./modules/constructors.js"; //selecionar elementos
import menu from "./modules/menu.js";
import contatoForm from "./modules/contatoForm.js";
import mostrarSenha from "./modules/mostrarSenha.js";
import showFormErrors from "./modules/showFormErrors.js";
import initCounterUp from "./modules/counter.js";
import MyHeader from "./header.js";
import Myfooter from "./footer.js";
import Mycontato from "./contato.js";
import Myclientes from "./clientes.js";
import scriptsHome from "./modules/scriptsPaginas/home.js";
import scriptsSobre from "./modules/scriptsPaginas/sobre.js";
import scriptsAbacateiro from "./modules/scriptsPaginas/abacateiro.js";
import scriptsPortfolio from "./modules/scriptsPaginas/portfolio.js";

// let mediaQuery = window.matchMedia('(min-width: 1024px)').matches
menu();
contatoForm();
mostrarSenha();
showFormErrors();

if (window.AOS) {
  AOS.init({
    disable: function () {
      var maxWidth = 768;
      return window.innerWidth < maxWidth;
    },
    startEvent: "DOMContentLoaded",
    offset: 30, // offset (in px) from the original trigger point
    delay: 100, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
  });
}

// PAGES
const pageHome = new Dom().el("#page__home");
const pageSobre = new Dom().el("#page__sobre");
const pagePortfolio = new Dom().el("#page__portfolio");
const pageAbacateiro = new Dom().el("#page__abacateiro");
const pageServicos = new Dom().el("#page__servicos");
const pageContato = new Dom().el("#page__contato");

if (pageHome) {
  // ★ HOME
  new Dom().bodyClass("body__home");
  scriptsHome();
  initCounterUp();
} else if (pageSobre) {
  // ★ Clientes
  new Dom().bodyClass("body__sobre");
  scriptsHome();
  scriptsSobre();
} else if (pagePortfolio) {
  new Dom().bodyClass("body__portfolio");
  scriptsPortfolio();
} else if (pageAbacateiro) {
  new Dom().bodyClass("body__abacateiro");
  scriptsAbacateiro();
} else if (pageServicos) {
  new Dom().bodyClass("body__servicos");
} else if (pageContato) {
  new Dom().bodyClass("body__contato");
}

document.addEventListener("DOMContentLoaded", () => {
  document.body.classList.add("dcl");
});
