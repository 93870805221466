export default function scriptsPortfolio() {
  const btns = document.querySelectorAll(".btns-portfolio button");
  const sections = document.querySelectorAll("section[data-section]");
  const imgs = document.querySelectorAll(".img-banner [data-section]");
  const btnFilters = document.querySelector(".filter-wrapper .btn-filter")
  const modalFilters = document.querySelector(".modal-filters")
  const btnAplicarFilters = document.querySelector(".modal-filters .btns .aplicar-filters")
  const btnRemoverFilters = document.querySelector(".modal-filters .btns .remove-filters")
  const badgeFilter = document.querySelector(".filter-wrapper .badge")
  const domain = window.location.origin
  // const inputSearch = document.querySelector("#pesquisa")

  // Verifica se é mobile
  const isMobile = window.matchMedia("(max-width: 768px)").matches

  function btnFiltersToggle(){
    modalFilters.classList.toggle("active")
  }
  btnFilters.addEventListener("click", btnFiltersToggle)

  const btnFotografia = document.querySelector('#fotografia .btn')
  btnFotografia.addEventListener("click", () => fetchFotografia(true))

  const btnVideo = document.querySelector('#audiovisual .btn')
  btnVideo.addEventListener("click", () => fetchVideo(true))
  
  // function handleSearch(){
  //   inputSearch.addEventListener('keyup', verifyLengthValid);
  //   function verifyLengthValid(event) {
  //     event.preventDefault();
  //     const validsLengths = [
  //       this.value.length > 3,
  //       this.value.length === 0,
  //     ]
  //     const isValid = validsLengths.includes(true)

  //     const fetchs = {
  //       fotografia: fetchFotografia,
  //       audiovisual: fetchVideo,
  //     }

  //     if (isValid) {
  //       btns.forEach((btn) => {
  //         if (btn.classList.contains("active")) {
  //           const section = btn.getAttribute("data-section")
  //           fetchs[section]()
  //         }
  //       });
  //     }
  //   }
  // }
  // handleSearch()

  // function clearInputSearch(){
  //   inputSearch.value = ''
  //   fetchFotografia()
  //   fetchVideo()
  // }

  function toggleActiveClass() {
    // clearInputSearch()

    btns.forEach((btn) => btn.classList.remove("active"));
    this.classList.add("active");

    sections.forEach((section) => {
      if (section.dataset.section === this.dataset.section) {
        section.style.display = "block";
      } else {
        section.style.display = "none";
      }
    });

    imgs.forEach((img) => {
      if (img.dataset.section === this.dataset.section) {
        img.style.display = "block";
      } else {
        img.style.display = "none";
      }
    });
  }

  btns.forEach((btn) => btn.addEventListener("click", toggleActiveClass));

  const recentFilter = document.querySelector('#recents')
  function toggleOrderFilter(){
    const order = recentFilter.getAttribute('data-order')
    const isAsc = order === 'asc'
    isAsc ? recentFilter.setAttribute('data-order', 'desc') : recentFilter.setAttribute('data-order', 'asc')

    fetchFotografia(false)
    fetchVideo(false)
  }
  recentFilter.addEventListener('click', toggleOrderFilter)

  function handlerUrl(){
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("q");
    const elementSection = document.querySelector(".header-wrapper");
  
    if (myParam === "audiovisual") {
      document.querySelector(".btns-portfolio .btn-2").click();
      elementSection.scrollIntoView({ behavior: "smooth" });
    } else if (myParam === "fotografia") {
      document.querySelector(".btns-portfolio .btn-1").click();
      elementSection.scrollIntoView({ behavior: "smooth" });
    }
  }
  handlerUrl();

  function categoriesFetch(){
    fetch(`${domain}/home/api/portifolio/`)
    .then(response => response.json())
    .then(data => {
      const uniqueIds = [];

      const categorias = data.results.filter(element => {
        const isDuplicate = uniqueIds.includes(element.categoria);

        if (!isDuplicate) {
          uniqueIds.push(element.categoria);

          return true;
        }

        return false;
      });
      
      const categoriaHtml = categorias.map(({categoria}) => {
        return `
          <span class="categoria">${categoria}</span>
        `
      }).join('')

      const categoriaEl = document.querySelector('.modal-filters .categories')
      categoriaEl.innerHTML = categoriaHtml
      
    }).catch(error => {
      console.error('Erro ao carregar dados da API:', error);
    });
  }
  categoriesFetch()

  // let pageFotografia = 1
  let hasNextFotografia = true
  let pageSizeFotografia  =  isMobile ? 5 : 17

  function fetchFotografia(increment = false, categorias = ''){
    // const sectionFotografia = document.querySelector("#fotografia")
    const order = recentFilter.getAttribute('data-order')

    if (increment) incrementFotografia()

    const categoriaFilter = categorias ? `&categoria=${categorias}` : ''
    const filtersUrl = `?tipo__icontains=fotografia${categoriaFilter}&page_size=${pageSizeFotografia}&order=${order}`
    
    const completeUrl = `${domain}/home/api/portifolio/${filtersUrl}`
    fetch(completeUrl)
    .then(response => response.json())
    .then(data => {
      // const activeBtn = document.querySelector(".btns-portfolio button.active");
      // const activeSection = activeBtn.getAttribute("data-section")
      // if(activeSection == "fotografia"){
      //   if(data.results.length > 0){
      //     sectionFotografia.style.display="block"
      //   } else{
      //     GLOBAL.showToastify("Não foi encontrado nenhum resultado para essa pesquisa")
      //     sectionFotografia.style.display="none"
      //   }
      // }
      const content = data.results.map(({ nome, categoria, imagem_destacada })=> {
        return `
          <div data-fancybox="gallery" href="${imagem_destacada}" class="hover-img-wrapper">
              <div class="tag hover-img">
                  <span>${categoria}</span>
              </div>
              <div class="img">
                  <img src="${imagem_destacada}" alt="">
              </div>
              <div class="nome-busca-wrapper hover-img">
                  <div class="nome-img">
                      <p>${nome}</p>
                  </div>
                  <div class="icon-search">
			                <svg><use xlink:href="#icon-search2"></use></svg>
                  </div>
              </div>
          </div>
        ` 
      }).join('');

      const dataEl = document.querySelector('#fotografia .imgs-grid')

      if(data.next) {
        hasNextFotografia = true
      } else {
        hasNextFotografia = false
        btnFotografia.style.display = 'none'
      }

      dataEl.innerHTML = content
    })
    .catch(error => {
      console.error('Erro ao carregar dados da API:', error);
    });
  }
  fetchFotografia();

  function incrementFotografia(){
    if(hasNextFotografia) pageSizeFotografia += pageSizeFotografia
  }

  // let pageVideo = 1
  let hasNextVideo = true
  let pageSizeVideo  =  isMobile ? 5 : 6

  function fetchVideo(increment = false, categorias = ''){
    // const sectionVideo = document.querySelector("#audiovisual")
    const order = recentFilter.getAttribute('data-order')
    
    if (increment) incrementVideo()

    const categoriaFilter = categorias ? `&categoria=${categorias}` : ''
    const filtersUrl = `?tipo__icontains=video${categoriaFilter}&page_size=${pageSizeVideo}&order=${order}`

    const completeUrl = `${domain}/home/api/portifolio/${filtersUrl}`
    fetch(completeUrl)
    .then(response => response.json())
    .then(data => {
      // const activeBtn = document.querySelector(".btns-portfolio button.active");
      // const activeSection = activeBtn.getAttribute("data-section")
      // if(activeSection == "audiovisual"){
      //   if(data.results.length > 0){
      //     sectionVideo.style.display="block"
      //   } else{
      //     GLOBAL.showToastify("Não foi encontrado nenhum resultado para essa pesquisa")
      //     sectionVideo.style.display="none"
      //   }
      // }
      const content = data.results.map(({ nome, categoria, imagem_destacada, video })=> {
        return `
        <div data-fancybox href="${video}" class="pointer">
            <div class="hover-video-wrapper">
                <div class="tag hover-video">
                    <span>${categoria}</span>
                </div>
                <div class="video">
                    <img src="${imagem_destacada}" alt="">
                </div>
                <div class="hover-video">
                    <a href="#" class="btn">
			                  <svg><use xlink:href="#icon-video"></use></svg>
                        <span>VER VÍDEOS</span>
                    </a>
                </div>
                <div class="nome-video hover-video">
                    <p>${nome}</p>
                </div>
            </div>
        </div>
        ` 
      }).join('');

      const dataEl = document.querySelector('#audiovisual .videos-grid')

      if(data.next) {
        hasNextVideo = true
      } else {
        hasNextVideo = false
        btnVideo.style.display = 'none'
      }

      dataEl.innerHTML = content
    })
    .catch(error => {
      console.error('Erro ao carregar dados da API:', error);
    });
  }
  fetchVideo();

  function incrementVideo(){
    if(hasNextVideo) pageSizeVideo += pageSizeVideo
  }

  document.addEventListener("click", (e) =>{
    const fecharModal = e.target.closest(".filter-wrapper")
    if (fecharModal) return
    modalFilters.classList.remove("active")
  })

  modalFilters.addEventListener("click", (e) =>{
    const target = e.target.closest(".categoria")
    if (!target) return
    target.classList.add("active")

    btnRemoverFilters.addEventListener("click", () =>{
      target.classList.remove("active")
    })
  })

  btnAplicarFilters.addEventListener("click", () =>{
   const categoriasSelecionadas =  document.querySelectorAll(".modal-filters .categoria.active")
   const categoriasCombinadas = [...categoriasSelecionadas].map(i => i.innerHTML).join(',')
   fetchFotografia(false, categoriasCombinadas)
   fetchVideo(false, categoriasCombinadas)
    badgeFilter.innerHTML = categoriasSelecionadas.length
    if(categoriasSelecionadas.length >= 1){
      badgeFilter.style.display = "block"
    }
    modalFilters.classList.remove("active")
  })

  btnRemoverFilters.addEventListener("click", () =>{
   fetchFotografia(false)
   fetchVideo(false)
    badgeFilter.innerHTML = '0'
    badgeFilter.style.display = "none"
  })
}

// const swiperTagsFilter = new Swiper(".swiper-tags-filter", {
//   slidesPerView: 2,
//   spaceBetween: 16,
//   breakpoints: {
//     640: {
//       slidesPerView: 3,
//       spaceBetween: 16,
//     },
//     768: {
//       slidesPerView: 4,
//       spaceBetween: 16,
//     },
//     1024: {
//       slidesPerView: 5,
//       spaceBetween: 16,
//     },
//   },
// });