export default class Mycontato extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
        <section id="contato">
            <div class="container--personalizado">
                <div class="contato-wrapper">
                    <div class="contato-content">
                        <img class="ornamento-1" src="./assets/images/imgs-contato/ornamento-1.png" alt="">
                        <div class="section-title" data-aos="fade-down">
                            <img src="./assets/images/icons/icon-avocado-clientes.svg" alt="">
                            <h2>CONTATO</h2>
                        </div>
                        <p data-aos="fade-right" data-aos-delay="100">Nós acreditamos que esta conversa pode ser o início de uma incrível colaboração e amizade profissional.</p>
                        <div data-aos="fade-right" data-aos-delay="200" class="midias-contato">
                            <div class="email">
                                <img src="./assets/images/icons/icon-email-green.svg" alt="">
                                <a href="#">contato@avocado.com.br</a>
                            </div>
                            <div class="instagram">
                                <img src="./assets/images/icons/icon-instagram-green.svg" alt="">
                                <a href="#">avocado.midia</a>
                            </div>
                            <div class="whatsapp">
                                <img src="./assets/images/icons/icon-whatsapp-green.svg" alt="">
                                <a href="#">(00) 00000-0000</a>
                            </div>
                        </div>
                    </div>
                    <div class="grid-imgs">
                        <div class="coluna-1">
                            <div class="item">
                                <img src="./assets/images/imgs-sobre/img-2.webp" alt="">
                            </div>
                            <div class="item">
                                <img src="./assets/images/imgs-portfolio/img-4.webp" alt="">
                            </div>
                            <div class="item">
                                <img src="./assets/images/imgs-portfolio/img-2.webp" alt="">
                            </div>
                        </div>
                        <div class="coluna-2">
                            <div class="item">
                                <img src="./assets/images/imgs-portfolio/img-6.webp" alt="">
                            </div>
                            <div class="item">
                                <img src="./assets/images/imgs-portfolio/img-3.webp" alt="">
                            </div>
                            <div class="item">
                                <img src="./assets/images/imgs-portfolio/img-10.webp" alt="">
                            </div>
                        </div>
                        <div class="coluna-3">
                            <div class="item">
                                <img src="./assets/images/imgs-portfolio/img-7.webp" alt="">
                            </div>
                            <div class="item">
                                <img src="./assets/images/imgs-portfolio/img-11.webp" alt="">
                            </div>
                        </div>
                    </div>
                    <form class="contato-form">
                        <div class="form-group">
                            <label for="nome">Nome</label>
                            <input type="text" name="nome" id="nome">
                        </div>
                        <div class="form-group">
                            <label for="email">E-mail</label>
                            <input type="text" name="email" id="email">
                        </div>
                        <div class="form-group">
                            <label for="telefone">Telefone</label>
                            <input type="text" name="telefone" id="telefone">
                        </div>
                        <div class="form-group">
                            <label for="mensagem">Mensagem</label>
                            <textarea name="mensagem" id="mensagem" cols="30" rows="5"></textarea>
                        </div>
                        <div class="btn-contato">
                            <button class="btn">Enviar mensagem</button>
                        </div>
                    </form>
                    <img class="ornamento-2" src="./assets/images/imgs-contato/ornamento-2.png" alt="">
                </div>
            </div>
        </section>
        `;
  }
}

customElements.define("my-contato", Mycontato);
