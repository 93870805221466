export default function scriptsHome() {
  // 'colocar scripts das respectivas paginas dentro de -scriptsPaginas-  e criar o arquivo exemplo: home.js - contato.js  --- e importar no main e executar em sua respectiva pagina '
  const bannersSlide = document.querySelectorAll(
    "#banner__home .banner-wrapper"
  );

  function myOverFunction(e) {
    const bannerWrapper = e.target.closest(".banner-wrapper");
    bannersSlide.forEach((banner) => {
      banner.classList.remove("active");
    });
    bannerWrapper.classList.add("active");
  }
  bannersSlide.forEach((banner) => {
    banner.addEventListener("mouseover", myOverFunction);
  });

  function itemSwiperActive() {
    const listItens = document.querySelectorAll(".card-list p");

    listItens.forEach((p, index) =>
      p.addEventListener("click", () => {
        swiperGuaka.slideTo(index, 0);
      })
    );
  }

  itemSwiperActive();

  const swiperClientes = new Swiper(".swiper-clientes", {
    slidesPerView: 2.5,
    spaceBetween: 16,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      640: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 32,
      },
    },
  });

  const swiperGuaka = new Swiper(".swiper-guaka", {
    slidesPerView: 1,
    effect: "fade",
    navigation: {
      nextEl: "#conheca-guaka__home .swiper-button-next",
      prevEl: "#conheca-guaka__home .swiper-button-prev",
    },
    pagination: {
      el: "#conheca-guaka__home .swiper-pagination",
      type: "fraction",
    },
  });

  const swiperSobre = new Swiper(".swiper-sobre", {
    slidesPerView: "auto",
    // spaceBetween: 32,
    navigation: {
      nextEl: "#sobre__home .swiper-button-next",
      prevEl: "#sobre__home .swiper-button-prev",
    },
  });

  const swiperBannerHome = new Swiper(".swiper-banner-home", {
    slidesPerView: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
    },
  });

  // breakpoint where swiper will be destroyed
  // and switches to a dual-column layout
  const breakpoint = window.matchMedia("(min-width:1024px)");
  // keep track of swiper instances to destroy later
  let swiperPortfolio;
  let swiperNumerosSobre;

  const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      if (swiperPortfolio !== undefined) swiperPortfolio.destroy(true, true);
      if (swiperNumerosSobre !== undefined) {
        swiperNumerosSobre.destroy(true, true);
      }
      // or/and do nothing
      return;
      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      return enableSwiper();
    }
  };

  const enableSwiper = function () {
    swiperPortfolio = new Swiper(".swiper-portfolio", {
      spaceBetween: 16,
      slidesPerView: 1.2,
      breakpoints: {
        640: {
          slidesPerView: 2.2,
          spaceBetween: 32,
        },
        768: {
          slidesPerView: 3.2,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 32,
        },
      },
    });
    swiperNumerosSobre = new Swiper(".swiper-numeros-sobre", {
      spaceBetween: 16,
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
      },
    });
  };

  // keep an eye on viewport size changes
  breakpoint.addListener(breakpointChecker);
  // kickstart
  breakpointChecker();
}
