export default function scriptsAbacateiro() {
  const swiperAbacateiroMobile = new Swiper(".swiper-abacateiro-mobile", {
    slidesPerView: 1,
    spaceBetween: 32,
    navigation: {
      nextEl: "#nosso-abacateiro .swiper-button-next",
      prevEl: "#nosso-abacateiro .swiper-button-prev",
    },
  });
}
