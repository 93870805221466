export default function menu() {
  const btnMobile = document.getElementById("btn-mobile");
  const headerEl = document.getElementById("header");
  const bodyEl = document.querySelector("body");

  function toggleMenu(event) {
    if (event.type === "touchstart") event.preventDefault();

    headerEl.classList.toggle("active");
    bodyEl.classList.toggle("menu-aberto");
    const active = headerEl.classList.contains("active");
    event.currentTarget.setAttribute("aria-expanded", active);

    if (active) {
      event.currentTarget.setAttribute("aria-label", "Fechar menu");
    } else {
      event.currentTarget.setAttribute("aria-label", "Abrir menu");
    }
  }

  const swiperMenu = new Swiper(".swiper-menu", {
    slidesPerView: 1.2,
    centeredSlides: true,
    spaceBetween: 16,
    breakpoints: {
      // 640: {
      //   slidesPerView: 1,
      //   spaceBetween: 16,
      // },
      768: {
        centeredSlides: false,
        slidesPerView: "auto",
        spaceBetween: 32,
      },
    },
  });

  btnMobile?.addEventListener("click", toggleMenu);

  window.addEventListener("scroll", function () {
    headerEl.classList.toggle("sticky", window.scrollY > 150);
  });
}
