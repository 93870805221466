export default function scriptsSobre() {
  const swiperSobreMobile = new Swiper(".swiper-sobre-mobile", {
    slidesPerView: 1,
    spaceBetween: 32,
    navigation: {
      nextEl: "#sobre .swiper-button-next",
      prevEl: "#sobre .swiper-button-prev",
    },
  });
}
